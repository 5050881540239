<template>
    <div>
        <checkbox-input :value="checked">
            {{ checkpoint.handler_payload.label }}
        </checkbox-input>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {

    data: () => ({
        checked: false
    }),

    computed: mapGetters('checkpoint', [
        'checkpoint'
    ])

}
</script>